import React from 'react'
import Routes from '../routes'
import Login from './login'
import ForgotPassword from './login/forgot-password'
import PasswordRecoveryAmplify from './login/password-recovery-amplify'
import ScrollToTop from 'components/scroll-to-top'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import * as Cookies from 'js-cookie';
import useAuth from '../hooks/useAuth'


const Main = ({ type }) => {
  const history = useHistory()
  const { logout } = useAuth()
  const location = useLocation()

  const hasToken = () => {
    const cookies = Cookies.get('userInfo')
    if (cookies) {
      const parsed = JSON.parse(cookies)
      return cookies && Object.keys(parsed).length > 0
    }
    return false
  }

  // User has switched back to the tab
  const onFocus = () => {
    const isLoggedIn = hasToken()

    if (!isLoggedIn && location.pathname !== '/') {
      logout()
      history.push('/')
      history.go(0)
    }
  }

  React.useEffect(() => {
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus)
    }
  })

  return <>
    {
      !hasToken()
        ? <Switch>
            <Route path='/wachtwoord-reset' exact>
              <PasswordRecoveryAmplify />
            </Route>
            <Route path='/wachtwoord-vergeten' exact>
              <ForgotPassword />
            </Route>
            <Route path='/' exact>
              <Login />
            </Route>
          </Switch>
        : <Routes type={type} />
    }
    <ScrollToTop />
  </>
}

export default Main
