import React from 'react'
import { Box, Typography, Container, Card, CardContent } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';
import PasswordResetAmplify from './password-reset-amplify'

const ForgotPassword = () => {
  return <Container
      maxWidth="sm"
      sx={{ py: 10 }}
    >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <RouterLink to="/">
        Terug
      </RouterLink>
    </Box>
    <Card>
      <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 4
          }}
        >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            mb: 3
          }}
        >
          <div>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              Password Reset
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Reset your account password using your code
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            mt: 3
          }}
        >
          <PasswordResetAmplify />
        </Box>
      </CardContent>
    </Card>
  </Container>
}

export default ForgotPassword
