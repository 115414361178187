import RoleBasedGuard from 'components/role-based-guard'
import React, { lazy } from 'react'

import {
  Switch,
  Route
} from 'react-router-dom'

import MainLayout from './layouts/main-layout'
import WithLayout from './with-layout'

const Dashboard = lazy(() => import('./components/dashboard'))
const Profile = lazy(() => import('./components/employee/profile'))
const ProfileEdit = lazy(() => import('./components/employee/profile-edit'))
const AccountSettings = lazy(() => import('./components/employee/account-settings'))
const VacatureDetail = lazy(() => import('./components/employer/vacature-detail'))
const JobsEdit = lazy(() => import('./components/employer/jobs-edit'))
const JobWizard = lazy(() => import('./components/employer/job-wizard'))
const Kandidaten = lazy(() => import('./components/employer/candidates'))
const KandidatenView = lazy(() => import('./components/employer/candidates/candidates-view'))
const CompanyProfile = lazy(() => import('./components/employer/company-profile'))
const ForgotPassword = lazy(() => import('./components/login/forgot-password'))

const Routes = () => {
  return <Switch>
    <Route
      exact
      path='/overview'
      render={matchProps => (
        <WithLayout
          {...matchProps}
          component={Dashboard}
          layout={MainLayout}
        />
      )}
    />
    <Route
      exact
      path='/profile'
      render={matchProps => (
        <WithLayout
          {...matchProps}
          component={Profile}
          layout={MainLayout}
        />
      )}
    />
    <Route
      exact
      path='/account-settings'
      render={matchProps => (
        <WithLayout
          {...matchProps}
          component={AccountSettings}
          layout={MainLayout}
        />
      )}
    />
    <Route
      exact
      path='/profile/edit'
      render={matchProps => (
        <WithLayout
          {...matchProps}
          component={ProfileEdit}
          layout={MainLayout}
        />)
      }
    />
    <Route
      exact
      path='/stages-en-vacatures/:id'
      render={matchProps => (
        <WithLayout
          {...matchProps}
          component={VacatureDetail}
          layout={MainLayout}
        />
      )}
    />
    <Route
      exact
      path='/stages-en-vacatures/edit/:id'
      render={matchProps => (
        <WithLayout
          {...matchProps}
          component={JobsEdit}
          layout={MainLayout}
        />
      )}
    />
    <Route
      exact
      path='/nieuwe-vacature'
      render={matchProps => (
        <WithLayout
          {...matchProps}
          component={JobWizard}
          layout={MainLayout}
        />
      )}
    />
    <Route
      exact
      path='/kandidaten'
      render={matchProps => (
        <WithLayout
          {...matchProps}
          component={Kandidaten}
          layout={MainLayout}
        />
      )}
    />
    <Route
      exact
      path='/kandidaten/:id'
      render={matchProps => (
        <WithLayout
          {...matchProps}
          component={KandidatenView}
          layout={MainLayout}
        />
      )}
    />
    <Route
      exact
      path='/bedrijfsprofiel'
      render={matchProps => (
        <RoleBasedGuard roles={['Superuser', 'MyHiswaUser', 'MyHiswaAdmin', 'MyHiswaSU']}>
          <WithLayout
            {...matchProps}
            component={CompanyProfile}
            layout={MainLayout}
          />
        </RoleBasedGuard>
      )}
    />
    <Route
      path='/'
      render={matchProps => (
        <WithLayout
          {...matchProps}
          component={Dashboard}
          layout={MainLayout}
        />
      )}
    />
  </Switch>
}

export default Routes
