import React from 'react'
import { makeStyles } from '@material-ui/styles'
// import Grid from '@material-ui/core/Grid'
// import Sidebar from './side-bar'
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import SideBarNav from './side-bar-nav'
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

export const SIDEBAR_WIDTH = 240

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    backgroundColor: '#fff'
  },
  main: {
    '& .inner': {
      paddingTop: 55
    }
  },
  PageTitle: {
    zIndex: 1199,
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0
  },
  paper: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.secondary.contrastText} !important`,
  }
}));


const getPages = (role) => {
  if (role === 'Superuser' || role === 'MyHiswaUser' || role === 'MyHiswaAdmin' || role === 'MyHiswaSU') {
    return [
      {
        title: 'Overzicht',
        href: '/overview'
      },
      {
        title: 'Kandidaten',
        href: '/kandidaten'
      },
      {
        title: 'Bedrijfsprofiel',
        href: '/bedrijfsprofiel'
      }
    ]
  }

  if (role === 'MyHiswaStudent') {
    return [
      {
        title: 'Overzicht',
        href: '/overview'
      },
      {
        title: 'Profiel',
        href: '/profile'
      }
    ]
  }

  return []
}

export const LayoutContext = React.createContext({
  title: '',
  actions: []
})

const MainLayout = ({ children }) => {
  const [title, setTitle] = React.useState('Beheer')
  const [actions, setActions] = React.useState([])

  const currRole = localStorage.getItem('authRole');
  const classes = useStyles()
  const [open] = React.useState(true)

  const pages = getPages(currRole)

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return <LayoutContext.Provider
    value={{
      setTitle,
      setActions
    }}
  >
    <Box
      sx={{ display: 'flex' }}
    >
      <AppBar
        position="fixed"
      >
        <Toolbar
          variant='dense'
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {
            actions.map((action, index) => {
              const ActionComp = action
              return <span key={index}>{ActionComp}</span>
            })
          }
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: SIDEBAR_WIDTH },
          flexShrink: { sm: 0 },
          zIndex: 1001
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: SIDEBAR_WIDTH },
          }}
        >
          <SideBarNav pages={pages} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: SIDEBAR_WIDTH },
          }}
          open
        >
          <SideBarNav pages={pages} />
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} className={classes.main}>
        <Container maxWidth='lg'>
          {
            children
          }
        </Container>
      </Box>
    </Box>
  </LayoutContext.Provider>

  // return <div className={classes.root}>
  //   {/* <Topbar title={currRole} /> */}
  //   <Sidebar
  //     open={open}
  //     pages={pages}
  //     title={currRole}
  //   />
  //   <Container maxWidth='lg'>
  //     <div className={classes.PageTitle}>
  //       <Paper square className={classes.paper}>
  //         <Toolbar>
  //             {' '}
  //         </Toolbar>
  //       </Paper>
  //     </div>
  //     <main className={classes.main}>
  //       {
  //         children
  //       }
  //     </main>
  //   </Container>
  // </div>
}

export default MainLayout
