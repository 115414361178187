const BASE_URL = 'https://vacatures.myhiswa.addsite.io/user/auth'

class UserClient {
  async getUserInfo (token) {
    const response = await fetch(`${BASE_URL}/idp-token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `token=${token}`
    })
    const responseJSON = await response.json()
    return responseJSON
  }

  async getUserByOnceKey (key) {
    const response = await fetch(`${BASE_URL}/idp-once`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `key=${key}`
    })
    const responseJSON = await response.json()
    return responseJSON
  }
}

export default UserClient
