import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField, Card, CardContent, Container, Typography } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import useMounted from '../../hooks/useMounted';

const PasswordRecoveryAmplify = () => {
  const mounted = useMounted();
  const { passwordRecovery } = useAuth();
  const history = useHistory();

  return (
    <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <RouterLink to="/">
              Terug naar login
            </RouterLink>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          />
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Wachtwoord opvragen
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Voer je e-mailadres in
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <Formik
                  initialValues={{
                    email: '',
                    submit: null
                  }}
                  validationSchema={Yup
                    .object()
                    .shape({
                      email: Yup
                        .string()
                        .email('Dit is geen e-mailadres')
                        .max(255)
                        .required('E-mailadres is verplicht')
                    })}
                  onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                      await passwordRecovery(values.email);

                      history.push('/wachtwoord-vergeten', {
                        state: {
                          username: values.email
                        }
                      });
                    } catch (err) {
                      console.error(err);
                      if (mounted.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                      }
                    }
                  }}
                >
                  {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        autoFocus
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="E-mailadres"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      <Box sx={{ mt: 3 }}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Nieuw wachtwoord opvragen
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
  );
};

export default PasswordRecoveryAmplify;
