import React from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  Divider,
  Link
} from '@material-ui/core'

import useAuth from '../hooks/useAuth'
import { useHistory, NavLink as RouterLink } from 'react-router-dom'
import MyProfileClient from 'clients/my-profile'
import { useQuery } from 'react-query'
import { FOS_URL } from '../config'

const myProfileClient = new MyProfileClient()

const SideBarNav = ({ pages }) => {
  const history = useHistory()
  const { logout, user } = useAuth()

  const { data } = useQuery(
    'my-profile',
    myProfileClient.getProfile,
    {
      refetchOnWindowFocus: false
    }
  )

  const handleLogout = () => {
    logout()
    history.push('/')
    history.go(0)
  }

  const ProfileName = data?.data?.name

  return <List
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      paddingTop: 9
    }}
  >
    {
      pages.map((page, index) => {
        return <ListItem
          button
          key={index}
          component={RouterLink}
          to={page.href}
          activeClassName="exactMatch"
          sx={{'&.exactMatch':{backgroundColor:'#F4F5F7'}}}>
            <ListItemText primary={page.title}/>
          </ListItem>
      })
    }
    <Box sx={{ display: 'flex', flexGrow: 1}} />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
      }}
    >
      {
        user?.role === 'MyHiswaStudent' && <ListItem
          button
          component={RouterLink}
          to="/account-settings"
          activeClassName="exactMatch"
          sx={{'&.exactMatch':{backgroundColor:'#F4F5F7'}}}>
          <ListItemText primary='Instellingen'/>
        </ListItem>
      }
      <ListItem button onClick={handleLogout}>
        <ListItemText primary={ProfileName || localStorage.getItem('authName') || ''} secondary='Uitloggen'/>
      </ListItem>
      <Divider />
      <Link
        component={Button}
        target='_blank'
        href={FOS_URL}
        rel="noreferrer"
        rel="noopener"
      >
        Bezoek vacatures overzicht
      </Link>
    </Box>
  </List>
}

export default SideBarNav
