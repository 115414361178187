import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import UserClient from '../clients/user'

import { Auth } from 'aws-amplify'
import { useCookies } from "react-cookie"
import * as Cookies from 'js-cookie';

import { COOKIE_DOMAIN } from '../config'

// const cookieStorage = new CookieStorage({ domain: 'hiswarecron.nl' })

// Auth.configure({
//   cookieStorage: {
//     domain: 'localhost',
//     secure: false,
//     path: '/',
//     expires: 365
//   }
// })

const userClient = new UserClient()

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => ({ ...state }),
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

const getUserInfo = async (id) => {
  const userInfo = await userClient.getUserInfo(id)
  return userInfo
}

export const AuthProvider = (props) => {
  const { children } = props;
  const [cookie, setCookie] = useCookies(['userInfo'])
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const jwtToken = user?.signInUserSession?.idToken?.jwtToken
        const userInfo = await getUserInfo(jwtToken)

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.attributes.sub,
              email: user.attributes.email,
              role: userInfo.user.role
            }
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const user = await Auth.signIn(email, password);

    if (user.challengeName) {
      console.error(`Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`);
      return;
    }

    const jwtToken = user?.signInUserSession?.idToken?.jwtToken
    const userInfo = await getUserInfo(jwtToken)

    setCookie('userInfo', JSON.stringify(userInfo), {
      path: '/',
      secure: process.env.NODE_ENV === 'production',
      domain: process.env.NODE_ENV === 'production'
        ? '.hiswarecron.nl'
        : 'localhost',
    })

    localStorage.setItem('authToken', userInfo.token);
    localStorage.setItem('authRefreshToken', userInfo.refresh_token);
    localStorage.setItem('authTokenExpires', userInfo.expires);
    localStorage.setItem('authRole', userInfo.user.role);
    localStorage.setItem('authName', userInfo.user.name);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: user.attributes.sub,
          email: user.attributes.email,
          token: userInfo.token,
          role: userInfo.user.role
        }
      }
    });
  };

  const logout = async () => {
    await Auth.signOut();

    localStorage.removeItem('authToken');
    localStorage.removeItem('authRole');

    Cookies.remove('userInfo', { domain: COOKIE_DOMAIN, path: '' })

    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email, password) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username, code) => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
