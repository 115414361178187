
const API_DOMAIN = 'https://vacatures.myhiswa.addsite.io';

export const fetchAuthorized = async (resource, options) => {
    let token = localStorage.getItem('authToken');

    if (resource.substr(0, 8) !== 'https://') {
        resource = API_DOMAIN + resource;
    }

    const refreshToken = async () => {
        const refreshToken = localStorage.getItem('authRefreshToken');
        if (!refreshToken) {
            console.error("No refresh token found. Please login again.");
            return;
        }
        // const headers = new Headers();
        // headers.append('Content-Type', 'application/x-www-formdata-urlencoded');
        const rsp = await fetch(API_DOMAIN + '/user/auth/idp-refresh', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'refresh_token=' + refreshToken
        });
        const json = await rsp.json();
        if (!json || json.error) {
            console.error(json?.error || 'Failed to refresh token');
            return
        }

        localStorage.setItem('authToken', json.token);
        return json.token;
    }

    if (token) {
        let tokenExpires = localStorage.getItem('authTokenExpires');
        if (tokenExpires < Math.floor(Date.now() / 1000)) {
            token = await refreshToken();
        }

        options = options || {};
        if (!('headers' in options)) options.headers = {};
        options.headers.Authorization = `Bearer ${token}`;
    }

    let response = await fetch(resource, options);
    let json = await response.json();
    if (json.error && json.error === 'invalid_token_or_expired') {
        token = await refreshToken();
        options.headers.Authorization = `Bearer ${token}`;
        response = await fetch(resource, options);
        json = await response.json();
    }

    return json;
}
