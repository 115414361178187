import { fetchAuthorized } from "fetch-authorized"

const BASE_URL = '/objects/endpoint/my/profile'
class MyProfileClient {
  async updateProfile (values) {
    const response = await fetchAuthorized(BASE_URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })

    return response
  }

  async getProfile () {
    const response = await fetchAuthorized(`${BASE_URL}`)
    return response
  }

  async filters () {
    const response = await fetch('https://vacatures.myhiswa.addsite.io/objects/endpoint/profile/filters')
    const responseJSON = await response.json()
    return responseJSON
  }
}

export default MyProfileClient
