import React from 'react'
import useAuth from '../hooks/useAuth'
import LoginForm from './login/login-form'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Login = () => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const { login } = useAuth()
  const history = useHistory()
  const classes = useStyles()

  const signIn = async ({ email, password }) => {
    setError(null)
    setLoading(true)
    try {
      await login(email, password)
      history.push('/overview')
      history.go(0)
      setLoading(false)
    } catch(e) {
      setError(e?.message)
      setLoading(false)
    }
  }

  return <div className={classes.formContainer}>
    <LoginForm
      handleSubmit={signIn}
      loading={loading}
      error={error}
    />
  </div>
}

export default Login
