import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Main from './components/main'
import { createCustomTheme } from './theme'
import useSettings from './hooks/useSettings'
import { AuthProvider } from './contexts/amplify-context';
import { ReactQueryDevtools } from 'react-query/devtools'
import amplifyConfig from './aws-exports'
import Amplify from 'aws-amplify';

import {
  BrowserRouter as Router
} from 'react-router-dom'


import { CookiesProvider } from 'react-cookie';

import {
  QueryClient,
  QueryClientProvider
} from 'react-query'

const queryClient = new QueryClient()

amplifyConfig.Storage = {
  AWSS3: {
    bucket: 'addsite-assets',
    region: 'eu-west-1'
  }
}

amplifyConfig.Auth = {
  identityPoolId: process.env.REACT_APP_AWS_IDEN_POOL_ID,
  region: 'eu-west-1',
  userPoolId: process.env.REACT_APP_AWS_WEB_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_WEB_CLIENT_ID,
  cookieStorage: {
    domain: process.env.NODE_ENV === 'production'
      ? '.hiswarecron.nl'
      : 'localhost',
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    expires: 365
  }
}

Amplify.configure(amplifyConfig)

function App() {
  const { settings } = useSettings();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  })

  return <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <CookiesProvider>
          <Router>
            <Main />
          </Router>
        </CookiesProvider>
      </AuthProvider>
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
  </QueryClientProvider>
}

export default App;
