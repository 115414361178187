import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Box, TextField, Typography, Container, FormHelperText, Link } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import LoadingButton from '@material-ui/lab/LoadingButton'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

const validationSchema = yup
  .object()
  .shape({
    email: yup
      .string('Vul een gebruikernaam/email in')
      .email('Vul een geldig email adres in')
      .max(255)
      .required('Dit veld is verplicht'),
    password: yup
      .string()
      .max(255)
      .required('Dit veld is verplicht')
  })

const LoginForm = ({ handleSubmit, loading, error }) => {
  const classes = useStyles()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { email, password } = values
      if (handleSubmit) {
        handleSubmit({ email, password })
      }
    }
  })

  return <div className={classes.root}>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 3
              }}
            >
            <div>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                Inloggen
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Inloggen is alleen mogelijk als student van Saxion. Alleen via Saxion kan je een account krijgen
              </Typography>
            </div>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  fullWidth
                  autoFocus
                  id="email"
                  name="email"
                  label="Email-adres"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="outlined"
                />
                <Box mt={2}>
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Wachtwoord"
                    type='password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    variant="outlined"
                  />
                </Box>
                <Box mt={2}>
                  <LoadingButton
                    color='primary'
                    variant='contained'
                    type="submit"
                    size="large"
                    loading={loading}
                    fullWidth
                  >
                    Login
                  </LoadingButton>
                </Box>
                <Box mt={1}>
                  <Link
                    color="textSecondary"
                    component={RouterLink}
                    sx={{ mt: 1 }}
                    to="/wachtwoord-reset"
                    variant="body2"
                  >
                    Wachtwoord vergeten
                  </Link>
                </Box>
              </form>
            </Box>
            {
              error && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }} ><FormHelperText error>{error}</FormHelperText></Box>
            }
          </CardContent>
        </Card>
      </Container>
    </Box>
  </div>
}

export default LoginForm
