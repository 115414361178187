import React, { Suspense } from 'react'
import LoadingScreen from './components/loading-screen'

const WithLayout = ({ component: Component, layout: Layout, ...rest }) => {
  return <Suspense fallback={<LoadingScreen />}>
    <Layout >
      <Component {...rest} />
    </Layout>
  </Suspense>
}

export default WithLayout