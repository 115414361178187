const dev = process.env.NODE_ENV !== 'production'

export const branch = process.env.REACT_APP_BRANCH_NAME || 'master'

export const server = dev ? 'http://localhost:3001' : 'https://stagebank.hiswarecron.nl'

export const COOKIE_DOMAIN = dev
  ? 'localhost'
  : branch === 'master'
    ? '.hiswarecron.nl'
    : ''

export const MANAGEMENT_URL = dev
  ? 'http://localhost:3000'
    : branch === 'master'
      ? 'https://stagebeheer.hiswarecron.nl'
      : 'http://hiswa-recron-vacatures-beheer-release.s3-website-eu-west-1.amazonaws.com'

export const FOS_URL = dev
  ? 'http://localhost:3001'
  : branch === 'master'
    ? 'https://stagebank.hiswarecron.nl'
    : 'https://release-1.d1yk8v0vi8lc5p.amplifyapp.com'