const RoleBasedGuard = ({ roles, children }) => {
    const currRole = localStorage.getItem('authRole');

    if (roles.indexOf(currRole) === -1) {
        console.log(`Role denied: ${currRole}, allowed roles are: [ ${roles.join(', ')} ]`);
        return <></>
    }

    return <>
        {children}
    </>;
}

export default RoleBasedGuard;
